import classNames from 'classnames';
import React from 'react';

import { Alert, Paragraph } from 'components/misc/helpers';
import { useIsMobile } from 'shared/hooks';
import VerticalSpaceContainer from 'uicontainers/_shared/container/VerticalSpaceContainer';

import styles from './formSubmitted.module.scss';
import { useFormSubmittedSelectors } from './hooks';

const FormSubmitted = () => {
  const isMobile = useIsMobile();
  const { paymentInformationResponse } = useFormSubmittedSelectors();
  const alertLevel = paymentInformationResponse?.alert_level ?? 'info';
  const alertMsg = paymentInformationResponse?.alert_msg ?? null;

  return (
    <div
      className={classNames({
        [styles.formSubmitted]: true,
        [styles['is-mobile']]: isMobile,
      })}
    >
      <VerticalSpaceContainer>
        {alertMsg && <Alert message={alertMsg} type={alertLevel} showIcon />}

        <Paragraph>
          Your email will arrive in the next few minutes. Please note your email may have landed in
          your spam folder. If it does please move the email out of spam to receive payment. If you
          see any problems, please email professionals@newtonxcommunity.com. We’re here to help!
        </Paragraph>
      </VerticalSpaceContainer>
    </div>
  );
};

export default FormSubmitted;
