import { useSelector } from 'react-redux';

import { useActions } from 'shared/hooks';
import { RootState } from 'rootReducer';

import { sendPaymentInformation } from 'uicontainers/apps/survey/_shared/handlebars/_shared/actions';
import { countries } from 'components/form';

import { FormData } from './types';

import { selectPaymentInformationErrorResponse, selectPaymentInformationStatus } from './selectors';
import { useFeatureFlagValue } from 'uicontainers/apps/survey/SurveyFeatureFlags/hooks';
export const useShowFormActions = () =>
  useActions({
    sendPaymentInformation,
  });

export const useShowFormSelectors = () =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
  useSelector((state: RootState) => {
    return {
      paymentInformationErrorResponse: selectPaymentInformationErrorResponse(state),
      paymentInformationStatus: selectPaymentInformationStatus(state),
    };
  });

export const useHandleSubmit = () => {
  const { sendPaymentInformation } = useShowFormActions();
  const url = window.location.href;
  const basePaymentUrl = '/surveys:payment_info/';
  const useMess = useFeatureFlagValue('useMess');
  const paymentUrl = useMess ? basePaymentUrl + '?use_mess=true' : basePaymentUrl;

  return (formData: FormData) => {
    const { email, countryCodeId, phone, optIn } = formData;
    const country = countries.find(({ id }) => id === countryCodeId);
    if (!country) {
      throw new Error(`Could not find country for this id: ${countryCodeId}`);
    }

    const phoneWithCountryCode = `+${country.countryCallingCode}-${phone}`;

    sendPaymentInformation({
      url,
      email,
      phone: phoneWithCountryCode,
      optIn,
      paymentUrl: paymentUrl,
    });
  };
};
